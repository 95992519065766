<template>
  <div class="dialog">
    <el-dialog :title="title" :visible.sync="dialogVisible" :width="width" :close-on-click-modal="false">
      <slot></slot>
      <span slot="footer" class="dialog-footer" v-if="footer">
        <el-button type="primary" @click="comfirm">确 定</el-button>
        <el-button class="cancel" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    },
    footer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    comfirm() {
      this.$emit('comfirm');
    }
  },
  watch: {
    dialogVisible(visible) {
      if (!visible) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  background: #000;
  .el-dialog__header {
    border-bottom: 1px solid #1b1b1b;
    .el-dialog__title {
      color: #f3f3f3;
    }
  }
  .el-dialog__body {
    color: #f3f3f3 !important;
  }
  .el-dialog__footer {
    .cancel {
      color: #909399;
      border-color: #1b1b1b;
      background: #000;
      &:hover {
        background: #1b1b1b !important;
      }
    }
  }
}
</style>
