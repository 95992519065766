<template>
  <div class="add">
    <MapDialog title="添加地图" width="500px" @comfirm="comfirm" @cancel="cancel">
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-form-item label="地图名称" prop="name">
          <BaseRow justify="space-between">
            <BaseCol :num="22">
              <el-input v-model="form.name" placeholder="请输入"></el-input>
            </BaseCol>
          </BaseRow>
        </el-form-item>
      </el-form>
    </MapDialog>
  </div>
</template>

<script>
import MapDialog from '../components/dialog';
import BaseRow from '@/components/row/base-row';
import BaseCol from '@/components/col/base-col';
export default {
  data() {
    return {
      form: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入地图名称', trigger: 'blur' }]
      }
    };
  },
  methods: {
    async comfirm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { id } = JSON.parse(localStorage.getItem('FactoryMap'));
          const params = {
            factoryId: id,
            name: this.form.name
          };

          const { fail } = await this.$apis.electron.save(params);

          if (!fail) {
            this.cancel();
          }
        }
      });
    },
    cancel() {
      this.$router.back();
    }
  },
  components: {
    MapDialog,
    BaseRow,
    BaseCol
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
</style>
