<template>
  <div class="base-row">
    <el-row :type="type" :justify="justify">
      <slot></slot>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'flex'
    },
    justify: String
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
